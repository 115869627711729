import request from '@/utils/axios'

/** 服务中心数据列表 */
export function getList(params) {
  return request({
    url: '/server/center/data',
    method: 'get',
    params
  })
}

/** 查看下级经纪人 */
export function getSuborList(params) {
  return request({
    url: '/server/center/data/broker',
    method: 'get',
    params
  })
}
