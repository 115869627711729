<template>
  <div>
    <div class="filter-container">
      <el-form inline>
        <el-form-item>
          <el-input v-model="query.keyword" placeholder="请输入搜索关键词" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          <el-button type="danger" icon="el-icon-refresh" @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
        prop="uid"
        label="UID"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="agentName"
        label="服务中心名称"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="unlockingConsumesHighEnergyGoldCoins"
        label="解锁消耗高能金币数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="unlockConsumptionOfActiveGoldCoins"
        label="解锁消耗活动金币数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="unlockedConsumesHighEnergyGoldCoins"
        label="被解锁消耗高能金币数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="unlockedConsumptionOfActiveGoldCoins"
        label="被解锁消耗活动金币数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="totalNumberOfHighEnergyGiftCards"
        label="高能礼品卡总数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="numberOfHighEnergyGiftCardsUsed"
        label="高能礼品卡及使用数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        label="操作"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-row>
            <el-button type="primary" size="small" @click="seesubordinate(row.id)">查看下级高能经纪人</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="(val) => {
          page = val
          getList()
        }"
      />
    </div>
    <Form :id="id" ref="form" />
  </div>
</template>

<script>
import list from '@/mixins/list'
import * as api from '@/api/serviceCenterData'
import Form from './form'
export default {
  components: { Form },
  mixins: [list],
  data() {
    return {
      api,
      id: ''
    }
  },
  methods: {
    seesubordinate(id) {
      this.id = id
      this.$refs.form.isShow()
    }
  }
}
</script>
