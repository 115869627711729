<template>
  <div>
    <el-dialog
      title="下级高能经纪人"
      :visible.sync="show"
      width="80%"
    >
      <template v-if="show">
        <div class="filter-container">
          <el-form inline>
            <el-form-item>
              <el-input v-model="query.keyword" placeholder="请输入搜索关键词" clearable />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
              <el-button type="danger" icon="el-icon-refresh" @click="refresh">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table :data="list">
          <el-table-column
            prop="id"
            label="ID"
            align="center"
            show-overflow-tooltip
          />
          <el-table-column
            prop="nickname"
            label="高能经纪人昵称"
            align="center"
            show-overflow-tooltip
          />
          <el-table-column
            prop="attestMemberCount"
            label="认证会员数"
            align="center"
            show-overflow-tooltip
          />
          <el-table-column
            prop="recommendedMemberCount"
            label="推荐会员数"
            align="center"
            show-overflow-tooltip
          />
          <el-table-column
            prop="memberRechargeCoinsCount"
            label="会员充值金币数"
            align="center"
            show-overflow-tooltip
          />
          <el-table-column
            prop="unlockUseRechargeCoinsCount"
            label="解锁消耗充值金币数"
            align="center"
            show-overflow-tooltip
          />
          <el-table-column
            prop="unlockUseActivityCoinsCount"
            label="解锁消耗活动金币数"
            align="center"
            show-overflow-tooltip
          /><el-table-column
            prop="unlockedUseRechargeCoinsCount"
            label="被解锁消耗充值金币数"
            align="center"
            show-overflow-tooltip
          /><el-table-column
            prop="unlockedUseActivityCoinsCount"
            label="被解锁消耗活动金币数"
            align="center"
            show-overflow-tooltip
          />
        </el-table>
        <div class="page-container">
          <el-pagination
            background
            :total="count"
            :page-sizes="pageSizes"
            :page-size.sync="pageSize"
            :current-page.sync="page"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="refresh"
            @current-change="(val) => {
              page = val
              getList()
            }"
          />
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as api from '@/api/serviceCenterData'
export default {
  props: {
    id: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      api,
      list: [],
      count: 0,
      page: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50, 100],
      loading: false,
      query: {},
      queryTime: {},
      show: false
    }
  },
  watch: {
    id() {
      this.getList()
    }
  },
  methods: {
    refresh() {
      this.query = {}
      this.page = 1
      this.getList()
      this.queryTime = {}
    },
    search() {
      this.page = 1
      this.getList()
    },
    isShow() {
      this.show = true
    },
    getList() {
      this.loading = true
      this.api.getSuborList({ ...this.query, page: this.page, pageSize: this.pageSize, id: this.id })
        .then(res => {
          const { list = [], count = 0 } = res.data
          this.list = list
          this.count = count
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
